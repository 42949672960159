import React from 'react';

import {
  BrowserRouter,
  Routes,
  Route,
  Link
} from "react-router-dom";

import Marketing1 from './pages/Marketing1';
import Marketing2 from './pages/Marketing2';
import Marketing3 from './pages/Marketing3';
import Marketing4 from './pages/Marketing4';

import './App.css';

import logoTdc from './image/logo-tdc.png';


function App() {
  return (
    <BrowserRouter>
      <Routes>
         <Route path="/reno" element={<Marketing1 />} />
         <Route path="/annisa" element={<Marketing2 />} />
         <Route path="/anie" element={<Marketing3 />} />
         <Route path="/puput" element={<Marketing4 />} />
         <Route path="/" element={
          <>
          <div className='home'>
            <div className='head__logo-title'>
              <div>
                <img src={logoTdc} alt='Logo Tiga Dara Catering' />
              </div>
              <div>
                <h1>Client Form<span>.</span></h1>
              </div>
            </div>
            <div>Silahkan Pilih Marketing:</div>
            <ul className='home__select-marketing'>
              <Link to={`/reno`}>
                <li>
                Reno
                </li>
              </Link>
              <Link to={`/annisa`}>
                <li>
                Annisa
                </li>
              </Link>
              <Link to={`/anie`}>
                <li>
                Anie
                </li>
              </Link>
              <Link to={`/puput`}>
                <li>
                Puput
                </li>
              </Link>
            </ul>
          </div>
          </>
         }/>
         <Route path="*" element={
          <>
          <div className='alien'>
            <h1>
              404<span>!</span>
            </h1>
            <p>Halaman tidak Ditemukan!</p>
          </div>
          </>
         } />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
