import React from 'react'

const FormRundown = ({ capengName, eventDate, setCapengName, setEventDate }) => {
  return (
    <>
    <div>
        <label>Nama Capeng:</label>
        <input
        type="text"
        value={capengName}
        onChange={(e) => setCapengName(e.target.value)}
        required
        />
    </div>
    <div>
        <label>Tanggal Acara:</label>
        <input
        type="date"
        value={eventDate}
        onChange={(e) => setEventDate(e.target.value)}
        required
        />
    </div>
    </>
  )
}

export default FormRundown