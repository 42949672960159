import React from 'react'

const FormUndangan = ({ invitationLink, setInvitationLink }) => {
  return (
    <>
    <div>
        <label>Link Undangan:</label>
        <input
        type="text"
        value={invitationLink}
        onChange={(e) => setInvitationLink(e.target.value)}
        required
        />
    </div>
    </>
  )
}

export default FormUndangan